import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { functions, path, useApp, useWidth } from '@wap-client/core'
import { pageService } from '@wap-client/services'

import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor/Anchor'
import Image from '@/components/base/image'
import Icon from '@/components/base/icon'
import Button from '@/components/base/button/Button'
import NextLink from 'next/link'

import Search from '../search'

import { HeaderProps } from './types'
import Panel from './panel/Panel'

const Header: React.FC<HeaderProps> = ({
  logo,
  navigation,
  festivalID,
  test,
}) => {
  const app = useApp()
  const router = useRouter()
  const width = useWidth()

  const [isPanel, setIsPanel] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [queryResult, setQueryResult] = useState<any>()

  useEffect(() => {
    handleScroll()

    const query = new URLSearchParams(window?.location.search)
    if (query) {
      setQueryResult(query.get('festivalId'))
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [router])

  const handleScroll = () => {
    const scrollTop = window.scrollY

    setIsScrolled(scrollTop > 0)
  }

  const Logo = () => {
    const isDefaultLang = app.languages.some(
      (lang) => lang.code === app.language && lang.isDefault
    )

    return (
      <figure className="logo">
        <NextLink href="/">
          <Image {...logo} alt={app.settings.title} />
        </NextLink>
      </figure>
    )
  }

  const handleLanguage = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        const params = new URLSearchParams(window.location.search)

        router.push(
          `${path.app(app.environment, slug)}${
            params.toString() && '?' + params.toString()
          }`
        )
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const Language = () => {
    if (app.languages.length > 1) {
      return (
        <div className="navigation__language">
          <ul className="navigation__language__list">
            {app.languages.map((language, index) => (
              <li key={index}>
                <Button
                  size="none"
                  onClick={() => handleLanguage(language.id)}
                  className={functions.classnames(
                    app.language === language.name && 'current'
                  )}
                >
                  {language.name.substring(0, 2)}
                </Button>
                {app.languages.length - 1 === index ? null : (
                  <Icon name="dot" />
                )}
              </li>
            ))}
          </ul>
        </div>
      )
    }
  }

  const Navigation = () => {
    if (!navigation) return null

    if (width > 1025) {
      return (
        <nav className="navigation">
          <div className="navigation__menu">
            <ul>
              {navigation.map((item, index) => {
                return (
                  <li key={index}>
                    {item.href === app.settings.routes['events'].href &&
                    festivalID ? (
                      <Anchor href={item.href + `?festivalId=${festivalID}`}>
                        {item.title}
                        {navigation.length - 1 === index ? null : (
                          <Icon name="dot" />
                        )}
                      </Anchor>
                    ) : item.href === app.settings.routes['events'].href &&
                      queryResult ? (
                      <Anchor href={item.href + `?festivalId=${queryResult}`}>
                        {item.title}
                        {navigation.length - 1 === index ? null : (
                          <Icon name="dot" />
                        )}
                      </Anchor>
                    ) : (
                      <Anchor href={item.href}>
                        {item.title}

                        {navigation.length - 1 === index ? null : (
                          <Icon name="dot" />
                        )}
                      </Anchor>
                    )}
                  </li>
                )
              })}
            </ul>
            <Search festival={festivalID} onClose={() => setIsPanel(false)} />
          </div>
          {Language()}
        </nav>
      )
    } else {
      return (
        <Button className="menu-button" onClick={() => setIsPanel(!isPanel)}>
          <Icon name={isPanel ? 'close' : 'menu'} size={'large'} />
        </Button>
      )
    }
  }

  return (
    <>
      <header
        className={functions.classnames(
          'header',
          isScrolled && 'header--scrolled',
          width < 1025 && 'header--scrolled'
        )}
      >
        <Container size={width > 1025 ? 'extended' : 'fluid'}>
          <Row xs={{ justify: 'between', align: 'center' }}>
            <Column xs={{ auto: true }}>{Logo()}</Column>
            <Column xs={{ auto: true }}>{Navigation()}</Column>
          </Row>
        </Container>
      </header>

      {navigation && (
        <Panel
          navigation={navigation}
          isOpen={isPanel}
          onClose={() => setIsPanel(false)}
        />
      )}
    </>
  )
}

export default Header
