/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react'

import { functions, useApp } from '@wap-client/core'

import Button from '@/components/base/button'
import Icon from '@/components/base/icon'
import SearchResult from './search-result'
import { SearchProps } from './search-result/types'

const Search: React.FunctionComponent<SearchProps> = ({
  festival,
  onClose,
}) => {
  const app = useApp()

  const [activeSearch, setActiveSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [scrollPosition, setScrollPosition] = useState(0)

  const searchRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleSearch = () => {
    setActiveSearch(true)
  }

  const closeSearch = (e: any) => {
    e.stopPropagation()
    setSearchValue('')
    setActiveSearch(false)
    if (searchInputRef.current) {
      searchInputRef.current.blur()
    }
  }

  const handleChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  const handler = (e: any) => {
    if (!searchRef.current?.contains(e.target)) {
      setSearchValue('')
      if (searchInputRef.current) {
        searchInputRef.current.blur()
      }
      setTimeout(() => {
        setActiveSearch(false)
      }, 500)
    }
  }

  const handleScroll = () => {
    if (window.scrollY < scrollPosition || window.scrollY > scrollPosition) {
      setSearchValue('')
      if (searchInputRef.current) {
        searchInputRef.current.blur()
      }
      setTimeout(() => {
        setActiveSearch(false)
      }, 500)
    }
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [scrollPosition])

  document.addEventListener('mousedown', handler)

  return (
    <div
      className={functions.classnames(
        'search',
        activeSearch && 'search--active'
      )}
      ref={searchRef}
    >
      <div className="search__form" onClick={() => handleSearch()}>
        <Button
          className="search__form__button search__form__button--icon search__form__button--icon"
          size="none"
        >
          <Icon name="search-button" />
        </Button>
        <input
          type="text"
          name="search"
          onChange={handleChange}
          value={searchValue}
          placeholder={app.settings.translations['searchEventV2']}
          ref={searchInputRef}
        />
        <Button
          onClick={(e) => closeSearch(e)}
          className="search__form__button search__form__button--close"
          size="none"
        >
          <Icon name="close" />
        </Button>
      </div>

      <SearchResult
        searchValue={searchValue}
        festival={festival}
        onClose={onClose}
        onCloseSearch={() => {
          setSearchValue('')
          setActiveSearch(false)
        }}
      />
    </div>
  )
}

export default Search
