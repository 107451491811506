import React, { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { pageService } from '@wap-client/services'
import { functions, path, useApp } from '@wap-client/core'

import { Column, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon'
import Button from '@/components/base/button/Button'

import PanelSearch from './panel-search/PanelSearch'

import { PanelProps } from '../types'

const Panel: React.FunctionComponent<PanelProps> = ({
  navigation,
  isOpen,
  onClose,
}) => {
  const app = useApp()
  const router = useRouter()

  const [isActive, setIsActive] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null)
  const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null)

  const handleMenuItem = (index: number) => {
    setActiveMenuIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const handleLanguage = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const Language = () => {
    if (app.languages.length > 1) {
      return (
        <div className="panel__language">
          <ul className="panel__language__list">
            {app.languages.map((language, index) => (
              <li key={index}>
                <Button
                  size="none"
                  onClick={() => handleLanguage(language.id)}
                  className={functions.classnames(
                    app.language === language.name && 'current'
                  )}
                >
                  {language.name.substring(0, 2)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )
    }
  }

  useEffect(() => {
    setIsActive(isOpen)
  }, [isOpen])

  return (
    <div className={functions.classnames('panel', isActive && 'panel--active')}>
      <Row>
        <Column xs={{ size: 12 }}>
          <PanelSearch isOpen={isOpen} onClose={onClose} />
        </Column>
      </Row>
      <Row>
        <Column xs={{ size: 12 }}>
          <nav className="panel__menu">
            <ul>
              {navigation.map((menuItem, index) => (
                <li
                  key={index}
                  className={functions.classnames(
                    menuItem.className,
                    menuItem.children.length && 'hasSubMenu',
                    activeSubMenu === menuItem.title && 'active'
                  )}
                >
                  <div
                    className={functions.classnames(
                      'menu-item',
                      activeMenuIndex === index && 'menu-item--active'
                    )}
                  >
                    <Anchor href={menuItem.href} onClick={onClose}>
                      {menuItem.title}
                      <Icon name="chev-right" size={'small'} />
                    </Anchor>
                    {menuItem.children.length > 0 && (
                      <Icon
                        name="arrow-right"
                        onClick={() => handleMenuItem(index)}
                      />
                    )}
                  </div>
                  {menuItem.children.length > 0 && (
                    <ul
                      className={functions.classnames(
                        'subMenu',
                        activeMenuIndex === index && 'subMenu--active'
                      )}
                    >
                      {menuItem.children.map((subMenu, index) => (
                        <li key={index}>
                          <Anchor
                            className="menu-item"
                            href={subMenu.href}
                            onClick={onClose}
                          >
                            {subMenu.title}
                          </Anchor>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </Column>
      </Row>
      <Row>
        <Column xs={{ size: 12 }}>{Language()}</Column>
      </Row>
    </div>
  )
}

export default Panel
