/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'

import { functions, useApp } from '@wap-client/core'

import Button from '@/components/base/button'
import Icon from '@/components/base/icon'
import SearchResult from '@/components/widgets/search/search-result'
import { PanelSearchProps } from './types'

const PanelSearch: React.FunctionComponent<PanelSearchProps> = ({
  isOpen,
  onClose,
}) => {
  const app = useApp()
  const [activeSearch, setActiveSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = () => {
    setActiveSearch(true)
  }

  const closeSearch = (e: any) => {
    e.stopPropagation()
    setSearchValue('')
    setActiveSearch(false)
  }

  const handleChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('')
    }
  }, [isOpen])

  return (
    <div
      className={functions.classnames(
        'panel__search',
        activeSearch && 'panel__search--active'
      )}
      onClick={() => handleSearch()}
    >
      <div className="panel__search__form">
        <Button
          className="panel__search__form__button panel__search__form__button--icon"
          size="none"
        >
          <Icon name="search-button" />
        </Button>
        <input
          type="text"
          name="search"
          onChange={handleChange}
          value={searchValue}
          placeholder={app.settings.translations['searchEvent']}
        />
        <Button
          onClick={(e) => closeSearch(e)}
          className="panel__search__form__button panel__search__form__button--close"
          size="none"
        >
          <Icon name="close" />
        </Button>
      </div>

      <SearchResult searchValue={searchValue} onClose={onClose} />
    </div>
  )
}

export default PanelSearch
