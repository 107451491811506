import React, { useCallback, useEffect, useState } from 'react'
import { SearchEvents, SearchFestival, SearchResultProps } from './types'
import { functions, useApp } from '@wap-client/core'
import { queryService } from '@wap-client/services'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon'
import FestivalDate from '../../festival-date/FestivalDate'
import Spinner from '@/components/base/spinner'
import anchor from '@/components/base/anchor'

const SearchResult: React.FunctionComponent<SearchResultProps> = ({
  searchValue,
  festival,
  onClose,
  onCloseSearch,
}) => {
  const app = useApp()

  const [festivals, setFestivals] = useState<SearchFestival[]>([])
  const [events, setEvents] = useState<SearchEvents[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const getFestivals = useCallback(async (query?: string, language?: any) => {
    setIsLoading(true)

    const params = new URLSearchParams()

    if (query) {
      params.append('title', query)
    }

    if (festival) {
      params.append('festivalId', festival)
    }

    try {
      const response = await queryService.run<SearchFestival[]>(
        app.environment,
        {
          name: 'kty-festivaller-search-veri-sorgusu',
          language: language,
          query: params.toString(),
        },
        [
          {
            name: 'id',
            path: 'id',
            type: 'static',
          },
          {
            name: 'title',
            path: 'baslik',
            type: 'static',
          },
          {
            name: 'startDate',
            path: 'baslangicTarihi',
            type: 'static',
          },
          {
            name: 'endDate',
            path: 'bitisTarihi',
            type: 'static',
          },
          {
            name: 'image',
            path: 'gorsel',
            type: 'static',
          },
          {
            name: 'anchor',
            path: 'festivalLink',
            type: 'static',
          },
        ]
      )

      if (response) {
        setIsLoading(false)
        setFestivals(response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  const getEvents = useCallback(async (query?: string, language?: any) => {
    setIsLoading(true)

    const params = new URLSearchParams()

    if (query) {
      params.append('title', query)
    }

    try {
      const response = await queryService.run<SearchEvents[]>(
        app.environment,
        {
          name: 'kty-etkinlikler-search-veri-sorgusu',
          language: language,
          query: params.toString(),
        },
        [
          {
            name: 'title',
            path: 'baslik',
            type: 'static',
          },
          {
            name: 'anchor',
            path: 'page.path',
            type: 'static',
          },
        ]
      )

      if (response) {
        setIsLoading(false)
        setEvents(response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()

    if (searchValue) {
      params.append('title', searchValue)
    }

    if (searchValue.length > 1) {
      getFestivals(searchValue, app.language)
      getEvents(searchValue, app.language)
    }
  }, [searchValue, getEvents, getFestivals, app.language])

  const renderFestivals = () => {
    return (
      <div className="search-result__festivals">
        {festivals.map((festival, index) => (
          <Anchor
            href={`${festival.anchor.href}?festivalId=${festival.id}`}
            className="search-result__festivals__item"
            key={index}
            onClick={() => {
              onClose()
              onCloseSearch && onCloseSearch()
            }}
          >
            <figure>
              <Image {...festival.image} alt={festival.title} />
            </figure>
            <div className="detail">
              <div className="detail__title">{festival.title}</div>
              <div className="detail__date">
                <FestivalDate
                  startDate={festival.startDate}
                  endDate={festival.endDate}
                  version="v1"
                />
              </div>
            </div>
          </Anchor>
        ))}

        <Anchor
          className="search-result__festivals__more"
          href={app.settings.routes['festivals'].href}
          onClick={() => {
            onClose()
            onCloseSearch && onCloseSearch()
          }}
        >
          {app.settings.translations['allFestivals']}
          <Icon name="chev-right" size={'xsmall'} />
        </Anchor>
      </div>
    )
  }

  const renderEvents = () => {
    return (
      <div className="search-result__events">
        {festivals.length > 0 && <hr />}
        {events.map((events, index) => (
          <Anchor
            href={events.anchor}
            className="search-result__events__item"
            key={index}
            onClick={() => {
              onClose()
              onCloseSearch && onCloseSearch()
            }}
          >
            {events.title}
          </Anchor>
        ))}
      </div>
    )
  }

  const renderError = () => {
    return (
      <div className="search-result__error">
        {app.settings.translations['notFoundSearch']}
      </div>
    )
  }

  return (
    <div
      className={functions.classnames(
        'search-result',
        searchValue.length > 0 && 'search-result--active'
      )}
    >
      {!festivals.length && !events.length && renderError()}
      {festivals.length > 0 && renderFestivals()}
      {events.length > 0 && renderEvents()}
      {isLoading && <Spinner />}
    </div>
  )
}

export default SearchResult
